<template>
  <container-list-extend title="Visite" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <h6
          class="m-0 ml-1"
          v-if="satisfaction.satisfaction_description"
        >{{satisfaction.satisfaction_description}}</h6>
        <h6 class="m-0 ml-1" v-else>satisfaction n°{{satisfaction.id}}</h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="satisfaction.satisfaction_date">
            Le :
            {{ $moment(satisfaction.satisfaction_date).format("DD/MM/YYYY") }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        deleteBtn
        @click="updateSatisfaction"
        @delete="deleteSatisfaction"
        :loading="loading"
      >
        <base-form-row row>
          <base-input
            v-model="satisfaction.satisfaction_description"
            inputText="Libellé"
            :errors="feedback.satisfaction_description"
          ></base-input>
          <base-inputDatePicker
            v-model="satisfaction.satisfaction_date"
            inputText="Date de satisfaction"
            :errors="feedback.satisfaction_date"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Affaire"
            v-model.number="satisfaction.satisfaction_affaire_id"
            :options="affaires"
            :errors="feedback.satisfaction_affaire_id"
            field="affaire_identifiant"
          ></base-select>
        </base-form-row>

        <base-form-row row class="align-items-center">
          <div class="ml-2">
            <BaseInputNumber
              class="mr-3 rounded pt-2 pr-2"
              inputText
              :borderInactive="true"
              :iconActive="true"
              max="5"
              min="0"
              v-model.number="satisfaction.satisfaction_note_commerce"
            />
            <input
              type="range"
              min="0"
              max="5"
              class="custom-range border-0"
              v-model.number="satisfaction.satisfaction_note_commerce"
            />
          </div>
          <div class="w-100 pt-4">
            <div>COMMERCE</div>
            <div class="text-muted">
              <small>Communication, disponibilité, ponctualité, compréhension des besoins, suggestions techniques, réactivité, clarté, pertinence de l’offre technique et commerciale…</small>
            </div>
          </div>
        </base-form-row>
        <base-form-row row class="align-items-center">
          <div class="ml-2">
            <BaseInputNumber
              class="mr-3 rounded pt-2 pr-2"
              inputText
              :borderInactive="true"
              :iconActive="true"
              max="5"
              min="0"
              v-model.number="satisfaction.satisfaction_note_pilotage"
            />
            <input
              type="range"
              min="0"
              max="5"
              class="custom-range border-0"
              v-model.number="satisfaction.satisfaction_note_pilotage"
            />
          </div>
          <div class="w-100 pt-4">
            <div>PILOTAGE DE LA PRESTATION</div>
            <div class="text-muted">
              <small>Planification, reporting, réactivité, suivi et maitrise des délais…</small>
            </div>
          </div>
        </base-form-row>
        <base-form-row row class="align-items-center">
          <div class="ml-2">
            <BaseInputNumber
              class="mr-3 rounded pt-2 pr-2"
              inputText
              :borderInactive="true"
              :iconActive="true"
              max="5"
              min="0"
              v-model.number="satisfaction.satisfaction_note_technique"
            />
            <input
              type="range"
              min="0"
              max="5"
              class="custom-range border-0"
              v-model.number="satisfaction.satisfaction_note_technique"
            />
          </div>
          <div class="w-100 pt-4">
            <div>MAITRISE TECHNIQUE</div>
            <div class="text-muted">
              <small>Expertise technique, force de proposition, maitrise des outils…</small>
            </div>
          </div>
        </base-form-row>
        <base-form-row row class="align-items-center">
          <div class="ml-2">
            <BaseInputNumber
              class="mr-3 rounded pt-2 pr-2"
              inputText
              :borderInactive="true"
              :iconActive="true"
              max="5"
              min="0"
              v-model.number="satisfaction.satisfaction_note_qsser"
            />
            <input
              type="range"
              min="0"
              max="5"
              class="custom-range border-0"
              v-model.number="satisfaction.satisfaction_note_qsser"
            />
          </div>
          <div class="w-100 pt-4">
            <div>QSSER</div>
            <div class="text-muted">
              <small>Prise en compte des exigences qualité, santé, sécurité, environnement et radioprotection / comportement sur le terrain, niveau de compétence, attitude interrogative des collaborateurs…</small>
            </div>
          </div>
        </base-form-row>
        <base-form-row row class="align-items-center">
          <div class="ml-2">
            <BaseInputNumber
              class="mr-3 rounded pt-2 pr-2"
              inputText
              :borderInactive="true"
              :iconActive="true"
              max="5"
              min="0"
              v-model.number="satisfaction.satisfaction_note_prix"
            />
            <input
              type="range"
              min="0"
              max="5"
              class="custom-range border-0"
              v-model.number="satisfaction.satisfaction_note_prix"
            />
          </div>
          <div class="w-100 pt-4">
            <div>COMPETITIVITE</div>
            <div class="text-muted">
              <small>Rapport qualité / prix</small>
            </div>
          </div>
        </base-form-row>
        <base-form-row row class="align-items-center">
          <base-input-area
            v-model="satisfaction.satisfaction_commentaire"
            text="Synthèse"
            :errors="feedback.satisfaction_commentaire"
          ></base-input-area>
        </base-form-row>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";
import BaseSelect from "@/components/bases/Select.vue";

export default {
  name: "FormationsItem",
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseInput,
    BaseInputNumber,
    BaseSelect
  },
  data() {
    return {
      feedback: {},
      loading: false
    };
  },
  props: {
    satisfaction: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    lists: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    affaires: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  computed: {
    ...mapGetters({ data: "clients/data" })
  },
  methods: {
    ...mapActions({
      _updateSatisfaction: "clients/updateSatisfaction",
      _deleteSatisfaction: "clients/deleteSatisfaction"
    }),
    updateSatisfaction: function() {
      this.feedback = {};
      this.loading = true;
      this._updateSatisfaction(this.satisfaction)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteSatisfaction: function() {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteSatisfaction(this.satisfaction)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css"></style>